import React, { useEffect, useState } from 'react';
import * as S from './index.styles';
import Mixpanel from '../../../services/Mixpanel';
import EmailSupportCompleted from '../../../assets/EmailSupportCompleted.svg';
import { useSelector } from 'react-redux';
import { RootState } from '../../../state/store';

const TicketSubmission = () => {
    const { user } = useSelector((state: RootState) => state.session);
    useEffect(() => {
        Mixpanel.track('web_ticket-submition_page');
    }, []);

    return (
        <S.TicketContainer>
            <S.TicketImg src={EmailSupportCompleted} alt="Completed" />
            <S.BoldTicketText>Support message has been sent</S.BoldTicketText>
            <S.TicketText>We will get back to you shortly. </S.TicketText>
            <S.TicketText>Please check {user?.email ? user.email : 'your email'} for our response</S.TicketText>
            <S.HomeButton to="/">Return Home</S.HomeButton>
        </S.TicketContainer>
    );
};
export default TicketSubmission;
