import React, { useEffect, useState } from 'react';
import { Page } from '../../../../components/Page';
import * as S from '../../index.styles';
import * as utils from '../../utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import ExerciseCard from '../../components/ExerciseCard';
import { isToday, isWithinInterval, subDays } from 'date-fns';
import { useNavigate, useParams } from 'react-router';
import { ExerciseTypes } from '@apps/common-utilities';
import { useSelector } from 'react-redux';
import { Spinner, useModal } from '@apps/common-ui';
import Mixpanel from '../../../../services/Mixpanel';
import { RootState } from '../../../../state/store';
import { IExerciseDaySummary } from '@apps/common-utilities/src/types/exerciseTypes';
import BreatherCard from '../components/BreatherCard';
import { TenantApi } from '../../../../api/UsersApi';

const RMTForDay = () => {
    const navigate = useNavigate();
    const [currentIndex, setCurrentIndex] = useState(0);
    const [selectedExercise, setSelectedExercise] = useState<ExerciseTypes.IExerciseSummary>();
    const [daySummary, setDaySummary] = useState<ExerciseTypes.IExerciseDaySummary>();
    const [rmtExercises, setRMTExercises] = useState<ExerciseTypes.IExerciseSummary[]>([]);
    const [loadingHistory, setLoadingHistory] = useState(false);
    const { tenantUser } = useSelector((state: RootState) => state.session);

    const isCurrentExercise = (index: number) => index === currentIndex;
    const allAreCompleted = rmtExercises.every(exercise => exercise.complete);

    const { date } = useParams<{date: string}>();
    const [isCompletable, setIsCompletable] = useState(false);

    useEffect(() => {
        if (date && tenantUser) {
            setLoadingHistory(true);
            TenantApi.get(`/users/${tenantUser?.id}/exercise-plans/daily-summary`, {
                params: {
                    startDate: new Date(date).toISOString(),
                    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                    type: utils.ExerciseFilter.RMT
                } })
                .then((res: IExerciseDaySummary) => {
                    setDaySummary(res);
                    const rmt = res.exercises;
                    setRMTExercises(rmt);
                    const nextExerciseIndex = rmt.findIndex((exercise) => !exercise.complete && exercise.exerciseInfo.type === ExerciseTypes.ExerciseType.SETS_AND_BREATHS);
                    if (nextExerciseIndex) {
                        setCurrentIndex(nextExerciseIndex);
                    }
                }).catch(() => {
                    console.error('Error fetching exercise summary');
                }).finally(() => {
                    setLoadingHistory(false);
                });
        }
    }, [date, tenantUser]);

    useEffect(() => {
        if (date) {
            setIsCompletable(daySummary?.complete || isWithinInterval(new Date(date), { start: subDays(new Date(), 6), end: new Date() }));
        }
    }, [date, daySummary]);

    const onExerciseStart = (exercise: ExerciseTypes.IExerciseSummary, index: number) => {
        Mixpanel.track('web_rmt_start_exercise', {
            exercise: exercise.exerciseInfo.title,
            date,
            exerciseNumber: index + 1,
        });
        const assignedExercise = rmtExercises.find((ae) => ae.id === exercise.id);
        if (assignedExercise) {
            setSelectedExercise(assignedExercise);
        }
    };

    const getNumExercisesString = (): string => {
        const numNonRMT = rmtExercises.length;
        return `${numNonRMT} ${numNonRMT > 1 ? 'exercises' : 'exercise'}`;
    };

    if (selectedExercise) {
        navigate(`/modal/exercise/${date}/${selectedExercise.id}`);
    }

    if (loadingHistory) {
        return <Spinner />;
    }

    if (!date || !daySummary) {
        return null;
    }

    return (
        <Page>
            <S.Section>
                <S.BackButton
                  buttonType="tertiary"
                  to="/breather"
                >
                    <FontAwesomeIcon icon={faChevronLeft as IconProp} /> Back to all upcoming breather sessions
                </S.BackButton>
            </S.Section>
            <S.Section>
                {isToday(new Date(date)) ? (<S.Heading>Today&apos;s Breather Sessions</S.Heading>) : (<S.Heading>{utils.getMonthAndDate(new Date(date)) } Exercises</S.Heading>)}
            </S.Section>
            <S.Section>
                {isCompletable
                        ? <S.Subheading>{utils.getMonthAndDate(new Date(date))}, {new Date(date).getFullYear()} has {getNumExercisesString()} </S.Subheading> : <S.Subheading>These exercises will unlock on {utils.getMonthAndDate(new Date(date))}</S.Subheading>}
            </S.Section>
            <S.Section>
                {allAreCompleted && isCompletable && <S.AllCompletedBanner>You&apos;re done for today!</S.AllCompletedBanner>}
                {!!rmtExercises.length && (
                    <S.CardContainer>
                            {rmtExercises.map((exercise: ExerciseTypes.IExerciseSummary, index: number) => (
                                <ExerciseCard
                                  key={exercise.id}
                                  exercise={exercise}
                                  onStart={() => onExerciseStart(exercise, index)}
                                  isCurrentExercise={isCurrentExercise(index)}
                                  canComplete={isCompletable}
                                  exerciseIndex={`${index + 1} of ${rmtExercises.length}`}
                                  date={date}
                                  isPlanCompleted={daySummary.complete}
                                />
                        ))}
                    </S.CardContainer>
                    )}
                <BreatherCard />
            </S.Section>
        </Page>

    );
};

export default RMTForDay;
