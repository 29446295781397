import React, { useEffect } from 'react';
import * as S from './index.styles';
import Mixpanel from '../../services/Mixpanel';
import callSupportImage from '../../assets/CallSupport.svg';
import chatSupportImage from '../../assets/ChatSupport.svg';
import emailSupportImage from '../../assets/EmailSupport.svg';
import { faPhone, faMessage, faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLocation, useNavigate } from 'react-router';

const Support = () => {
    const phoneNumber = '(928) 296-9285';
    const navigate = useNavigate();
    const location = useLocation();
    const previousPage = location.state?.previousPage;

    useEffect(() => {
        Mixpanel.track('web_support_page');
    }, []);

    return (
        <S.SupportContainer>
            {!!previousPage && (
                <S.BackButton buttonType="tertiary" onClick={() => navigate(previousPage)}>
                    <FontAwesomeIcon icon={faChevronLeft as IconProp} />
                    Back
                </S.BackButton>
            )}
            <S.Content>
                <S.HeaderContainer>
                    <S.Header>Contact Support</S.Header>
                </S.HeaderContainer>
                <S.CardContainer>
                    <S.SupportCard>
                        <S.HeadingContainer>
                            <S.SupportImg src={callSupportImage} alt="Call" />
                            <S.SubHeader>Phone Call</S.SubHeader>
                        </S.HeadingContainer>
                        <S.DesktopText>Call {phoneNumber}</S.DesktopText>
                        <S.DesktopCallInfo>
                            If we are not available we will<br />
                            respond to your voicemail as soon<br />
                            as possible
                        </S.DesktopCallInfo>
                        <S.MobileText>{phoneNumber}</S.MobileText>
                        <S.MobileButton href="tel:+19282969285">
                            <S.ButtonIcon icon={faPhone as IconProp} />
                            Call our Support Team
                        </S.MobileButton>
                    </S.SupportCard>
                    <S.SupportCard>
                        <S.HeadingContainer>
                            <S.SupportImg src={chatSupportImage} alt="Chat" />
                            <S.SubHeader>SMS Text Message</S.SubHeader>
                        </S.HeadingContainer>
                        <S.DesktopText>Text {phoneNumber}</S.DesktopText>
                        <S.DesktopTextInfo>
                            We will respond as soon as<br />
                            possible via text message
                        </S.DesktopTextInfo>
                        <S.MobileText>{phoneNumber}</S.MobileText>
                        <S.MobileButton href="sms:+19282969285">
                            <S.ButtonIcon icon={faMessage as IconProp} />
                            Text our Support Team
                        </S.MobileButton>
                    </S.SupportCard>
                    <S.SupportCard>
                        <S.HeadingContainer>
                            <S.SupportImg src={emailSupportImage} alt="Email" />
                            <S.SubHeader>Message</S.SubHeader>
                        </S.HeadingContainer>
                        <S.SupportText>We will respond as soon as<br />possible via email</S.SupportText>
                        <S.EmailButton
                          onClick={() => navigate('/support/email-support', { state: { previousPage } })}
                        >Send us a Support Message
                        </S.EmailButton>
                    </S.SupportCard>
                </S.CardContainer>
            </S.Content>
        </S.SupportContainer>
    );
};

export default Support;
