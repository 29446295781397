import styled from 'styled-components';
import { Buttons } from '@apps/common-ui';

export const TicketContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

export const TicketImg = styled.img`
    width: 40px;
    padding-top: 60px;
    margin-bottom: 20px;
`;

export const TicketText = styled.p`
    margin-top: 0;
    margin-bottom: 20px;
    text-align: center;
`;

export const BoldTicketText = styled(TicketText)`
    font-weight: bold;
`;

export const HomeButton = styled(Buttons.LinkButton)`
    color: ${props => props.theme.buttons.white};
    background: ${props => props.theme.shades.blue};
    text-align: center;
    margin-top: 20px;
`;
