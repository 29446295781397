import { LearningTypes } from '@apps/common-utilities';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import Mixpanel from '../../../services/Mixpanel';
import { RootState } from '../../../state/store';
import * as S from './index.styles';
import SubmoduleCard from './SubmoduleCard';
import { Page } from '../../../components/Page';
import { TenantApi } from '../../../api/UsersApi';

const SubmodulesList = () => {
    const { moduleId } = useParams<{moduleId: string}>();
    const { course } = useSelector((state: RootState) => state.coaching);
    const { tenantUser } = useSelector((state: RootState) => state.session);
    const [module, setModule] = useState<LearningTypes.IUserModule>();
    const moduleThumbnail = course?.modules.find((m) => m.id === Number(moduleId))?.thumbnailUrl;

    const navigate = useNavigate();

    const getModuleData = () => {
        if (!tenantUser) {
            return;
        }
        TenantApi.get(`/users/${tenantUser.id}/courses/assigned/modules/${moduleId}`).then((res: LearningTypes.IUserModule) => {
            setModule(res);
        });
    };

    // get string to display in the current card banner. Ex: Lesson 5, Quiz 2, etc.
    const getCurrentSubmoduleString = () => {
        if (!course || !course?.currentSubModule) {
            return '';
        }

        if (course?.currentSubModule.subModuleType === LearningTypes.SubModuleType.LESSON) {
            return `Lesson ${course.currentSubModule.subModuleTypePosition}`;
        } else if (course?.currentSubModule.subModuleType === LearningTypes.SubModuleType.QUIZ) {
            return `Quiz ${course.currentSubModule.subModuleTypePosition}`;
        }
        return '';
    };

    const submoduleStarted = (submoduleId: number, type: LearningTypes.SubModuleType) => {
        if (!module) {
            return;
        }
        const submodule = module.subModules.find((s) => s.id === submoduleId);
        if (type === LearningTypes.SubModuleType.LESSON) {
            Mixpanel.track('web_view_lesson_button');
            navigate(`/modal/module/${moduleId}/lesson/${submoduleId}/${submodule?.completed}`);
        } else if (type === LearningTypes.SubModuleType.QUIZ) {
            Mixpanel.track('web_view_quiz_button');
            navigate(`/modal/module/${moduleId}/quiz/${submoduleId}/${submodule?.completed}/${Number(submodule?.quizScorePercentage) >= 0 ? Number(submodule?.quizScorePercentage) : -1}`);
        }
    };

    useEffect(() => {
        getModuleData();
    }, [tenantUser, course]);

    return (
        <Page>
            <S.PageContent>
                <S.BackButton
                  to="/learning/modules"
                  buttonType="tertiary"
                  onClick={() => Mixpanel.track('web_submodule_page_back_button')}
                >
                    <FontAwesomeIcon icon={faChevronLeft as IconProp} /> View all Learning Modules
                </S.BackButton>
                <S.TitleContainer>
                    <S.TitleRow>
                        <S.TitleImage src={moduleThumbnail} />
                        <div>
                            <S.Subtitle>{course?.title}</S.Subtitle>
                            <S.Title>Module {module?.moduleOrder}: {module?.title}</S.Title>
                        </div>
                    </S.TitleRow>
                </S.TitleContainer>
                <S.CardContainer>
                    {module?.subModules.map((submodule: LearningTypes.ISubModuleSummary, index: number) => (
                        <SubmoduleCard
                          onButtonClick={() => submoduleStarted(submodule.id, submodule.type)}
                          key={submodule.id}
                          {...submodule}
                          index={index + 1}
                          isCurrent={!!course?.currentSubModule && submodule.id === course?.currentSubModule.id}
                          currentSubmoduleString={getCurrentSubmoduleString()}
                          submoduleNumberOfType={submodule.typePosition}
                        />
                    ))}
                </S.CardContainer>
            </S.PageContent>
        </Page>
    );
};

export default SubmodulesList;
