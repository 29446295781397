import { Spinner, theme, useModal } from '@apps/common-ui';
import { LearningTypes, Url } from '@apps/common-utilities';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import NotFound from '../../../components/common/NotFound';
import { useActionLoader } from '../../../hooks/useActionLoader';
import Mixpanel from '../../../services/Mixpanel';
import { createLessonSubmission, fetchCourse } from '../../../state/reducers/coaching/actions';
import { RootState } from '../../../state/store';
import AudioModal from './AudioModal';
import * as S from './index.styles';
import { TenantApi } from '../../../api/UsersApi';

const Lesson = () => {
    const navigate = useNavigate();
    const [lesson, setLesson] = useState<LearningTypes.IUserLesson>();
    const [lessonNumber, setLessonNumber] = useState<number>();
    const [lessonFetched, setLessonFetched] = useState(false);
    const [alreadySubmitted, setAlreadySubmitted] = useState(false);

    const { moduleId } = useParams<{moduleId: string}>();
    const { id } = useParams<{id: string}>();
    const { completed } = useParams<{completed: string}>();

    const { tenantUser } = useSelector((state: RootState) => state.session);
    const { course } = useSelector((state: RootState) => state.coaching);

    const { isOpen, openModal, closeModal } = useModal();

    const { callAction: submitLesson, done: submissionDone, error: submissionError } = useActionLoader(createLessonSubmission, {
        successMessage: 'Lesson Submitted',
        errorMessage: 'Submission Error'
    });

    const { callAction: refreshCourse, done: courseRefreshDone } = useActionLoader(fetchCourse);

    useEffect(() => {
        setAlreadySubmitted(completed === 'true');
    }, [completed]);

    const getLessonData = () => {
        if (!tenantUser) {
            return;
        }

        TenantApi.get(`/users/${tenantUser.id}/courses/assigned/lessons/${id}`).then((res: LearningTypes.IUserLesson) => {
            setLesson(res);
        });

        TenantApi.get(`/users/${tenantUser.id}/courses/assigned/modules/${moduleId}`).then((res: LearningTypes.IUserModule) => {
            setLessonNumber(res.subModules.filter((submodule) => submodule.type === LearningTypes.SubModuleType.LESSON).findIndex((l) => l.id === Number(id)) + 1);
        });
    };

    const onSubmit = () => {
        if (!tenantUser) {
            return;
        }

        if (alreadySubmitted) {
            navigate(`/learning/modules/${moduleId}`);
        } else {
            const submissionBody: LearningTypes.IUserLessonSubmission = {
                lessonId: Number(id),
                completedAt: new Date(),
                completionType: LearningTypes.LessonCompletionType.READ
            };
            submitLesson({ userId: tenantUser.id, lessonSubmission: submissionBody });
        }
    };

    if (courseRefreshDone) {
        navigate(`/learning/modules/${moduleId}`);
    }

    useEffect(() => {
        getLessonData();
    }, [tenantUser, course]);

    useEffect(() => {
        setLessonFetched(false);
        getLessonData();
    }, []);

    useEffect(() => {
        if (!tenantUser || submissionError) {
            return;
        }
        if (submissionDone) {
            refreshCourse({ userId: tenantUser.id });
        }
    }, [submissionDone]);

    useEffect(() => {
        if (lesson) {
            setLessonFetched(true);
        }
    }, [lesson]);

    const moduleNumber = course ? course.modules.findIndex((module) => module.id === Number(moduleId)) + 1 : -1;
    const moduleLessonCount = course ? course.modules[moduleNumber - 1].lessonCount : -1;

    if (moduleNumber === -1 || moduleLessonCount === -1) {
        return <Spinner />;
    }

    if (!course || (lessonFetched && !lesson)) {
        return <NotFound errorMessage="Lesson not found" />;
    }

    const onBackClick = () => {
        Mixpanel.track('web_lesson_page_back_button');
        navigate(-1);
    };

    return (
        <>
            {isOpen
                && (
                <AudioModal
                  onDismiss={closeModal}
                  moduleId={Number(moduleId)}
                  videoUrl={lesson?.videoUrl || ''}
                  lessonNumber={lessonNumber || 0}
                  moduleLessonCount={moduleLessonCount}
                  moduleNumber={moduleNumber}
                  lessonId={Number(id)}
                  alreadySubmitted={alreadySubmitted}
                />
                )}
            <S.Container>
                <S.TitleBar>
                    <S.BackButton onClick={onBackClick}><FontAwesomeIcon icon={faChevronLeft as IconProp} /> Back</S.BackButton>
                </S.TitleBar>
                <S.PageContent>
                    <S.IndexContainer>
                        <S.ModuleLink onClick={() => Mixpanel.track('web_lesson_page_module_link')} to={`/learning/modules/${moduleId}`}>Module {moduleNumber}</S.ModuleLink>
                        <S.LessonIndex>Lesson {lessonNumber} of {moduleLessonCount}</S.LessonIndex>
                    </S.IndexContainer>
                    <S.LessonTitle>{lesson?.title}</S.LessonTitle>
                    {(lesson?.videoUrl &&  Url.isVimeoUrl(lesson?.videoUrl))? (
                        <S.ListenContainer>
                            <S.ListenContainerLeft>
                                <S.ListenIcon src="/ListenIcon.svg" />
                                <S.ListenText>Listen to this lesson</S.ListenText>
                            </S.ListenContainerLeft>
                            <S.ListenContainerRight>
                                <S.ListenButton
                                  onClick={() => {
                                    Mixpanel.track('web_lesson_page_listen_button');
                                    openModal();
                                }}
                                >
                                    Listen Now
                                </S.ListenButton>
                            </S.ListenContainerRight>
                        </S.ListenContainer>
                        ):(
                            <S.NoAudioContainer>
                            <p>An audio version of this lesson is not available.</p>
                            </S.NoAudioContainer>
                        )
                    }
                    <S.LessonContent dangerouslySetInnerHTML={{ __html: lesson ? lesson.content : '' }} />
                    <S.CompleteButton
                      onClick={() => {
                        Mixpanel.track('web_lesson_page_complete_button');
                        onSubmit();
                      }}
                    >
                        {alreadySubmitted ? 'Continue' : 'Complete & Continue'}
                    </S.CompleteButton>
                </S.PageContent>
            </S.Container>
        </>
    );
};

export default Lesson;
