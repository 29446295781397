import { Buttons, Card } from '@apps/common-ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

export const SupportContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 70vw;
  max-width: 1100px;
  margin: 0 20px;

  @media (max-width: ${props => props.theme.screen.medium}) {
    width: 100vw;
    padding: 0 20px;
  }
`;

export const TicketSubmissionContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const BackButton = styled(Buttons.Button)`
  margin: 0;
  padding: 0;
  align-self: flex-start;

  & svg:last-of-type {
    margin-left: 0;
  }
`;

export const SubHeader = styled.h2`
  margin-top: 20px;
  font-weight: normal;
  @media (max-width: ${props => props.theme.screen.medium}) {
    margin: 0;
    margin-left: 20px;
    padding-bottom: 0;
    padding-top: 0;
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  width: 100%;
`;

export const Header = styled(SubHeader)`
  @media (max-width: ${props => props.theme.screen.medium}) {
    margin-left: 0;
    padding-bottom: 20px;
    padding-top: 20px;
  }
`;

export const HeadingContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  @media (max-width: ${props => props.theme.screen.medium}) {
    flex-direction: row;
    justify-content: space-between;
    margin: 20px;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  @media (max-width: ${props => props.theme.screen.medium}) {
    padding: 0 0 0 10px;
  }
`;

export const CardContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;
  @media (max-width: ${props => props.theme.screen.medium}) {
    flex-direction: column;
  }
`;

export const SupportCard = styled(Card)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;
  width: 300px;
  height: 300px;
  margin: 0 0 40px 0;
  @media (max-width: ${props => props.theme.screen.medium}) {
    margin-right: 0;
    margin-bottom: 20px;
    align-items: flex-start;
    width: 80vw;
  }
`;

export const SupportText = styled.p`
  text-align: center;
  margin-top 0;
  margin-bottom: 5px;
  margin-left: 20px;
  margin-right: 20px;
  @media (max-width: ${props => props.theme.screen.medium}) {
    text-align: start;
  }
`;

export const DesktopCallInfo = styled(SupportText)`
  display: inline;
  margin-bottom: 30px;
  @media (max-width: ${props => props.theme.screen.medium}) { 
    display: none;
  }
`;

export const DesktopTextInfo = styled(SupportText)`
  display: inline;
  margin-bottom: 54px;
  @media (max-width: ${props => props.theme.screen.medium}) { 
    display: none;
  }
`;

export const SupportImg = styled.img`
  width: 60px;
  padding-top: 40px;
  @media (max-width: ${props => props.theme.screen.medium}) {
    padding-top: 0;
  }
`;

export const EmailButton = styled(Buttons.Button)`
  width: 90%;
  color: ${props => props.theme.buttons.white};
  background: ${props => props.theme.shades.blue};
  text-align: center;
  margin-top: 14px;
  margin-bottom: 35px;
  @media (max-width: ${props => props.theme.screen.medium}) { 
    margin-left: 20px;
    margin-right: 20px;
    width: 87%;
    margin-bottom: 20px;
  }
`;

export const DesktopText = styled(SupportText)`
  display: inline;
  margin-bottom: 20px;
  font-weight: bold;
  @media (max-width: ${props => props.theme.screen.medium}) { 
    display: none;
  }
`;

export const MobileButton = styled.a`
  ${Buttons.ButtonStyles}
  display: none;
  color: ${props => props.theme.buttons.white};
  background: ${props => props.theme.shades.blue};
  text-align: center;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 20px;
  width: 87%;
  @media (max-width: ${props => props.theme.screen.medium}) { 
    display: block;
  }

  & svg:first-of-type {
    margin-right: 10px;
  }
`;

export const MobileText = styled(SupportText)`
  display: none;
  margin-bottom: 20px;
  @media (max-width: ${props => props.theme.screen.medium}) { 
    display: inline;
  }
`;

export const ButtonIcon = styled(FontAwesomeIcon)`
  width: 14px;
  height: 14px;
`;
