import toast from 'react-hot-toast';
import { Routes } from '../api/Routes';
import { TenantApi } from '../api/UsersApi';

export interface SupportTicket {
    message: string
    fullName: string;
    email: string;
}

class CustomerSupport {
    async createTicket(ticket: SupportTicket, skipAuth: boolean): Promise<boolean> {
        return TenantApi.post(Routes.customerSupport.ticket, ticket, { skipAuth }).then((res) => {
            if (res) {
                toast.success('Ticket submitted successfully!', { duration: 8000 });
                return true;
            }
            throw new Error('Error submitting ticket. Please try again later.');
        }).catch((err) => {
            toast.error('Error submitting ticket. Please try again later.', { duration: 8000 });
            return false;
        });
    }
}

export default new CustomerSupport();
