import React, { useEffect, useState } from 'react';
import * as S from './index.styles';
import Mixpanel from '../../../services/Mixpanel';
import { useSelector } from 'react-redux';
import { RootState } from '../../../state/store';
import { Spinner } from '@apps/common-ui';

const TicketCreation = ({ submitTicket, done }:
    { submitTicket: Function, done: boolean }) => {
    const [ticketDetails, setTicketDetails] = useState('');
    const [fullName, setFullName] = useState('');
    const [email, setEmail] = useState('');
    const { user, tenantUser } = useSelector((state: RootState) => state.session);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (user) {
            const combinedName = `${tenantUser?.firstName} ${tenantUser?.lastName}`;
            setFullName(combinedName.trim());
            setEmail(user.email);
        }
        setLoading(false);
    }, [tenantUser, user]);

    const isValidEmail = (userEmail: string) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(userEmail);
    };

    const handleSubmit = () => {
        if (!ticketDetails.trim()) {
            setError('Please enter a message');
            return;
        }

        if (!fullName.trim()) {
            setError('Please enter your name');
            return;
        }

        if (!isValidEmail(email)) {
            setError('Please enter a valid email');
            return;
        }

        Mixpanel.track('web_support_ticket_submit_button');
        submitTicket(ticketDetails, fullName, email);
    };

    if (loading) {
        return <Spinner small />;
    }

    return (
        <S.TicketCard>
            <S.SupportInputContainer>
                { !user && (
                <>
                    <S.InputLabel htmlFor="email">Email</S.InputLabel>
                    <S.SupportInput
                      onChange={(e) => setEmail(e.target.value)}
                      disabled={done}
                    />

                    <S.InputLabel htmlFor="fullName">Full Name</S.InputLabel>
                    <S.SupportInput
                      onChange={(e) => setFullName(e.target.value)}
                      disabled={done}
                    />

                </>
                )}
                <S.TicketLabel>How can we help?</S.TicketLabel>
                { user
                ? <S.SupportText>We will respond as soon as possible via email to {user?.email}</S.SupportText>
                : <S.SupportText>We will respond as soon as possible via email</S.SupportText>}
                <S.SupportTextArea
                  onClick={() => Mixpanel.track('web_support_ticket_details')}
                  onChange={(e) => setTicketDetails(e.target.value)}
                  autoCapitalize="sentences"
                  disabled={done}
                  placeholder="Tell us what you are having trouble with"
                />
            </S.SupportInputContainer>
            <S.ErrorMessage>{error}</S.ErrorMessage>
            <S.Actions>
                <S.SubmitButton
                  disabled={done}
                  onClick={handleSubmit}
                >
                    Send Message
                </S.SubmitButton>
            </S.Actions>
        </S.TicketCard>
    );
};
export default TicketCreation;
