import { theme } from '@apps/common-ui';
import React from 'react';
import * as S from './index.styles';

type Props = {
    completed: boolean;
    targetCompletionDate: string;
    onButtonPress: () => void;
    viewModule: () => void;
    locked: boolean;
    lessonCount: number;
    quizCount: number;
    thumbnailUrl: string;
    moduleTitle: string;
    subModuleTitle: string;
    isCurrent: boolean;
    currentSubmoduleString: string;
    completedAt: string;
}

const ModuleCard = ({ completed, targetCompletionDate, onButtonPress, viewModule, locked, lessonCount, quizCount, thumbnailUrl, moduleTitle, subModuleTitle, isCurrent, currentSubmoduleString, completedAt }: Props) => {
    const width = window.innerWidth;
    const breakpoint = Number(theme.screen.small.split('px')[0]);

    const getDateString = (date: Date) => `${date.toLocaleString('default', { month: 'long' })} ${date.getDate()}, ${date.getFullYear()}`;
    const getLessonAndQuizCountString = () => {
        const lessonString = lessonCount ? `${lessonCount} ${lessonCount > 1 ? 'Lessons' : 'Lesson'}` : '';
        const quizString = quizCount ? `${quizCount} ${quizCount > 1 ? 'Quizzes' : 'Quiz'}` : '';
        if (lessonCount && quizCount) {
            return `${lessonString}, ${quizString}`;
        } else if (lessonCount && !quizCount) {
            return lessonString;
        } else if (!lessonCount && quizCount) {
            return quizString;
        }

        return '';
    };

    return (
        <S.ModuleCard onClick={() => width < breakpoint && !locked ? onButtonPress() : () => {}}>
            <S.CardTop>
                <S.CardTopLeft>
                    <S.ImageContainer bottomLeftRadius={!isCurrent}>
                        <S.ModuleImage src={thumbnailUrl} />
                    </S.ImageContainer>
                    <S.ModuleInfo>
                        <S.ModuleTitle isLocked={locked} onClick={() => !locked ? viewModule() : () => {}}>{moduleTitle}</S.ModuleTitle>
                        <S.ModuleLessons isCurrent={isCurrent}>{getLessonAndQuizCountString()}</S.ModuleLessons>
                    </S.ModuleInfo>
                </S.CardTopLeft>
                <S.CardTopRight>
                    {completed
                    && (
                    <>
                        <>
                            <S.CompletionDate>{`Completed ${completedAt ? getDateString(new Date(completedAt)) : ''}`}</S.CompletionDate>
                            <S.ViewButton onClick={onButtonPress}>View</S.ViewButton>
                        </>
                        <S.MobileCompletionIcon src="/CompleteIcon.svg" />
                    </>
                    )}
                    {!completed
                    && (
                        <>
                            <S.DetailsAndTargetDateContainer>
                                {isCurrent && <S.Details onClick={viewModule}>Details</S.Details>}
                                <S.TargetDate>Target completion date: {getDateString(new Date(targetCompletionDate))}</S.TargetDate>
                            </S.DetailsAndTargetDateContainer>
                            {locked && <S.LockedIcon src="/LockedIcon.svg" /> }
                            {!locked && !isCurrent && <S.ViewButton onClick={onButtonPress}>View</S.ViewButton>}
                        </>
                    )}
                </S.CardTopRight>
            </S.CardTop>
            {isCurrent
            && (
                <S.CardBottom>
                    <S.CurrentLessonBanner>
                        <S.CurrentLessonText>You are currently on {currentSubmoduleString}</S.CurrentLessonText>
                    </S.CurrentLessonBanner>
                    <S.ModuleDetailsContainer>
                        <S.ModuleDetails>
                            <S.SubModuleTitle>{subModuleTitle}</S.SubModuleTitle>
                            <S.MobileReadButton onClick={onButtonPress}>View</S.MobileReadButton>
                        </S.ModuleDetails>
                        <S.ButtonContainer>
                            <S.ReadButton onClick={onButtonPress}>View {currentSubmoduleString.split(' ')[0]}</S.ReadButton>
                        </S.ButtonContainer>
                    </S.ModuleDetailsContainer>
                </S.CardBottom>
            )}
        </S.ModuleCard>
    );
};

export default ModuleCard;
