import React, { useEffect, useState } from 'react';
import * as S from '../index.styles';
import TicketCreation from '../../../components/SupportTicket/TicketCreation';
import CustomerSupport, { SupportTicket } from '../../../services/CustomerSupport';
import Mixpanel from '../../../services/Mixpanel';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import TicketSubmission from '../../../components/SupportTicket/TicketCompletion';
import { useLocation, useNavigate } from 'react-router';
import { useSelector } from 'react-redux';
import { RootState } from '../../../state/store';

const EmailSupport = () => {
    const [completed, setCompleted] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const previousPage = location.state?.previousPage;
    const { tenantUser } = useSelector((state: RootState) => state.session);

    const submitTicket = (message: string, fullName: string, email: string) => {
        const ticket: SupportTicket = { message, fullName, email };
        CustomerSupport.createTicket(ticket, !tenantUser).then((successful: boolean) => {
            if (successful) {
                setCompleted(true);
            }
        });
    };

    useEffect(() => {
        Mixpanel.track('web_email-support_page');
    }, []);

    return (
        <S.SupportContainer>
            <S.BackButton
              buttonType="tertiary"
              onClick={() => navigate('/support', { state: { previousPage } })}
            >
                <FontAwesomeIcon icon={faChevronLeft as IconProp} />
                Back to support options
            </S.BackButton>
            {completed ? (
                <S.TicketSubmissionContainer>
                    <TicketSubmission />
                </S.TicketSubmissionContainer>
            ) : (
                <>
                    <S.Header>Send us a Support Message</S.Header>
                    <TicketCreation submitTicket={submitTicket} done={completed} />
                </>
            )}
        </S.SupportContainer>
    );
};

export default EmailSupport;
