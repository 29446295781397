import { addDays, isSameDay, startOfWeek } from 'date-fns';

export class NaiveDate extends Date {
    constructor(date: string) {
        const year = parseInt(date.substring(0, 4), 10);
        const month = parseInt(date.substring(5, 7), 10) - 1;
        const day = parseInt(date.substring(8, 11), 10);
        super(year, month, day);
    }
}

export const getDatesInWeek = (date: Date): Date[] => {
    const datesOfWeek = [];
    const weekStart = startOfWeek(date, { weekStartsOn: 1 });
    for (let i = 0; i < 7; i += 1) {
        datesOfWeek.push(addDays(weekStart, i));
    }

    return datesOfWeek;
};

export const getDayOfWeek = (date: Date): string => date.toLocaleString('default', { weekday: 'long' }).toLowerCase();

export const isToday = (date: Date): boolean => isSameDay(new Date(), date);

export const getMonthAndDate = (date: Date): string => `${date.toLocaleString('default', { month: 'long' })} ${date.getDate()}`;

export enum ExerciseFilter {
    ALL = 'All',
    RMT = 'RMT',
    EXERCISE = 'EXERCISE'
}
