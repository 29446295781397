import { Buttons, Card } from '@apps/common-ui';
import styled from 'styled-components';

interface StartProps {
    isCurrentExercise: boolean;
    isPlanCompleted: boolean;
}

export const ExerciseCard = styled(Card)`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 340px;
    max-height: 300px;
    padding-top: 0px;
    padding-left: 0px;
`;

export const CardTop = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
    flex-grow: 1;
`;

export const ImageContainer = styled.div`
    position: relative;
    top: 0px;
    left: 0px;
    width: 160px;
    height: 100px;
    object-fit: cover;
    margin-right: 20px;
`;
export const Thumbnail = styled.img`
    width: 170px;
    height: 100px;
    border-radius: 5px 0 0 0;
`;

export const ExerciseData = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding-top: 20px;
`;

export const ExerciseIndex = styled.p`
    font-weight: bold;
    color: ${props => props.theme.text.blue};
    margin-top: 0px;
    margin-bottom: 5px;
`;

export const EquipmentDetails = styled.p`
    margin-top: 0px;
    margin-bottom: 0px;
`;

export const CardBottom = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    padding-left: 20px;
    flex-grow: 1;
`;

export const ExerciseTitle = styled.p`
    font-weight: bold;
`;

export const StartButton = styled(Buttons.Button)<StartProps>`
    width: 100%;
    margin-left: 0px;

    color: ${props => (props.isCurrentExercise && !props.isPlanCompleted) ? props.theme.text.white : props.theme.buttons.blue};
    background: ${props => (props.isCurrentExercise && !props.isPlanCompleted) ? props.theme.buttons.blue : props.theme.shades.white};
    border-width: 2px;
    border-style: solid;
    border-color: ${props => props.theme.buttons.blue};
`;

export const CompletedStatus = styled.p`
    color: ${props => props.theme.text.success};
    font-weight: bold;

    svg {
        margin-right: 5px;
    }
`;
