import { Buttons, Card, Inputs } from '@apps/common-ui';
import { Input, InputContainer, TextArea } from '@apps/common-ui/src/Input';
import styled from 'styled-components';

export const TicketCard = styled(Card)`
  width: 100%;
`;

export const SupportInput = styled(Input)`
  max-width: 100%;
  margin-left: 0;
  background-color: ${props => props.theme.buttons.lightGrey} ;
`;

export const InputLabel = styled(Inputs.Label)`
  font-size: 16px;
  color: ${props => props.theme.text.default};
  width: auto;
  margin-left: 0px;
`;

export const SupportTextArea = styled(TextArea)`
  max-width: 100%;
  background-color: ${props => props.theme.buttons.lightGrey} ;
`;

export const SupportInputContainer = styled(InputContainer)`
  align-items: flex-start;
`;

export const Actions = styled.div`
  align-items: center;
  @media (max-width: ${props => props.theme.screen.medium}) { 
    width: 100%
  }
`;

export const SupportText = styled.p`
  margin-top: 0;
`;

export const TicketLabel = styled.p`
  font-weight: bold;
  margin-bottom: 5px;
  margin-top: 10px;
`;

export const SubmitButton = styled(Buttons.Button)`
  margin: 0;
  @media (max-width: ${props => props.theme.screen.medium}) { 
    width: 100%;
  }
`;

export const ErrorMessage = styled.p`
  color: ${props => props.theme.danger.medium};
  font-size: 16px;
`;
