import { Buttons } from '@apps/common-ui';
import styled from 'styled-components';

export const ModalContent = styled.div`
    padding-top: 10px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
    
    @media (max-width: ${props => props.theme.screen.medium}) {
        padding-top: 20px;
        min-width: 100vw;
        min-height: 100vh;
    }
`;

export const TitleBar = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
`;

export const TitleContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
`;

export const Title = styled.h2`
    margin-top: 10px;
`;

export const CloseButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;

    @media (max-width: ${props => props.theme.screen.medium}) {
        margin-right: 30px;
    }
`;

export const CloseButton = styled(Buttons.Button)`
    display: flex;
    flex-direction: row;
    justify-content flex-start;
    align-items: flex-start;
    background: ${props => props.theme.shades.white};
    border-color: ${props => props.theme.shades.white};
    margin-top: 0px;
    margin-right: 0px;
    padding-right: 0px;

    p {
        margin-top: 0px;
        margin-bottom: 10px;
        color: ${props => props.theme.primary.medium}
    }

    svg {
        margin-top: 6px;
        color: ${props => props.theme.primary.medium}
    }


`;

export const ChecklistMessage = styled.p`
    margin-top: 0px;
    width: 100%;
`;

export const ListContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    background: ${props => props.theme.backgrounds.lightBlue};
    width: 100%;
    border-radius: 17px;
    padding-left: 20px;
    padding-right: 20px;
`;

export const ListTitle = styled.h3`

`;

export const ListItem = styled.p`
    font-weight: bold;
    margin-top: 0px;
`;

export const ButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 100%;
    margin-top: 30px;

    @media (max-width: ${props => props.theme.screen.medium}) {
        justify-content: center;
    }
`;

export const VitalsButton = styled(Buttons.Button)`

`;
